.MuiTable-stickyHeader {
  .MuiTableHead-root {
    .MuiTableCell-head {
      padding: 0.5rem 0 0 0;
      vertical-align: middle;
      justify-content: center;
      align-items: center;

      &:first-of-type {
        padding: 0 0.75rem;
        align-items: flex-start;
      }

      &:first-of-type {
        align-items: flex-start;

        .Mui-TableHeadCell-Content {
          justify-content: flex-start;
          padding: 15px 0;
        }
      }

      .Mui-TableHeadCell-Content {
        justify-content: center;
        align-items: center;


      }
    }
  }
}

.dependency-pagination {
  [disabled] {
    opacity: .5;
  }

  .navigation-button {
    position: relative;

    &:not(disabled) {
      svg {
        position: relative;
      }

      &:before {
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        background-color: var(--white-color);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        z-index: 0;
        transform: translate(-50%, -50%);
      }
    }
  }
}
