.custom-select {
  z-index: 3;

  .select {
    &__indicator {
      &.select__dropdown-indicator {
        color: var(--primary-color);
      }
    }
    &__control {
      border-color: var(--primary-color) !important;
      box-shadow: none;
      border-radius: 0;
      min-height: 40px;
      .select__indicator-separator {
        background-color: transparent;
      }
      .select__value-container {
        input {
          padding-bottom: 4px !important;
        }
      }
    }
    &__menu {
      padding-right: 8px;
      padding-top: 16px;
    }
    &__menu-list {
      max-height: 100px;
      overflow: auto;
      text-transform: uppercase;
      padding: 0;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--primary-text-color);
        border-radius: 100px;
      }
    }
    &__option {
      background-color: transparent !important;
      cursor: pointer;
      color: black !important;
      padding: 10px 16px;

      &:first-child {
        margin-top: -14px;
      }
    }
  }
}
