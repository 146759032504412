.MuiCheckbox-colorError {
  path {
    fill: var(--error-color)
  }
}

.MuiCheckbox-root {
  &.Mui-disabled {
    opacity: .5;
  }
}
