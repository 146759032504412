.text-with-rounded-image {
  padding: 5px 0;
  border-bottom: 1px solid transparent;
  transition: .3s ease-in all;

  .img-border {
    border: 1px solid var(--tan-color-00);
    margin-right: 4px;
    width: 42px;
    height: 42px;

    &--small {
      width: 32px;
      height: 32px;
    }


    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      border: 3px solid var(--white-color);
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &--error {
    border-color: var(--error-color);
  }
}
