.button {
  text-transform: uppercase;
  padding: 14px;
  transition: all 0.5s;
  box-sizing: border-box;
  position: relative;

  &[disabled] {
    opacity: .5;
    pointer-events: none;
  }

  &-small {
    padding: 8px 12px;
  }

  &-primary {
    background-color: var(--primary-color) !important;
    color: var(--white-color);
    border: 2px solid var(--primary-color);

    svg {
      fill: var(--white-color);
    }

    &[disabled] {
      svg {
        path {
          fill: var(--white-color)
        }
      }
    }

    &:hover {
      border: 2px solid var(--white-color);
    }

    &[disabled] {
      background-color: var(--primary-color-03) !important;
      border: 2px solid var(--primary-color-03);
      pointer-events: none;
    }
  }

  &-secondary {
    background-color: var(--white-color) !important;
    color: var(--primary-color);
    border: 2px solid var(--white-color);

    &:hover {
      border: 2px solid var(--primary-color);
    }
  }

  &-tertiary {
    background-color: transparent;
    color: var(--primary-color);

    &[disabled] {
      opacity: 0.2;
      background: var(--white-color);
      pointer-events: none;
    }
  }

  &-outline {
    background-color: transparent;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);

    &:hover {
      border: 3px solid var(--primary-color);
    }

    svg {
      path {
        fill: var(--primary-color);

      }
    }
  }

  &-delete {
    background-color: var(--error-color) !important;
    color: var(--white-color);
    border: 2px solid var(--error-color);
  }

  &-logout {
    background-color: transparent;
    color: var(--white-color);
  }
}

.hidden-input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
}
