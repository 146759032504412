@layer base {
  body {
    font-family: var(--font-family);
    font-size: var(--font-regular);
    background-color: var(--body-background-color);
    color: var(--primary-text-color);
    padding: 0;
    margin: 0;
  }
}

#root {
  min-height: 100vh;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
