.MuiTableHead-root {
  .MuiTableCell-head {
    background-color: var(--tan-color-02);

    .header-cell-with-rounded-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .MuiBadge-badge {
      display: none;
    }
  }

  .MuiTableSortLabel-icon {
    margin: 0 !important;
  }

  .MuiTableSortLabel-root {
    opacity: 1 !important;
  }

  .Mui-TableHeadCell-Content-Actions {
    display: none;
  }
}

.actions-ico-wrapper {
  display: flex;
  justify-content: flex-end;

  .action-ico {
    padding: 0 2px;
    cursor: pointer;
  }
}

.Mui-TableBodyCell-DetailPanel {
  background-color: var(--tan-color-02);
}

.table-footer {
  .MuiTableContainer-root {
    + .MuiBox-root {
      bottom: 90px;
    }
  }
}

//Pagination
.MuiTableContainer-root:not(.has-custom-toolbar) {
  + .MuiBox-root {
    position: fixed;
    bottom: 0;
    background-color: var(--white-color);
    z-index: 100;
    @apply px-5;

    > div {
      padding: 0;
    }

    .MuiBox-root {
      &:nth-child(2) {
        width: 100%;
        display: flex;
        flex-direction: column;
        position: relative;

        .MuiBox-root {
          &:last-child {
            display: flex;
            flex-direction: row-reverse;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
            width: 100%;
            justify-content: space-between !important;
            @apply 2xl:container;
            @apply mx-auto;
          }
        }
      }
    }
  }

  .table-footer & {
    + .MuiBox-root {
      bottom: 90px;
    }
  }
}

.custom-table-pagination {
  > .MuiBox-root {
    flex-direction: row-reverse;
    justify-content: space-between !important;
    padding: 12px 0;
    width: 100%;
  }
}

.custom-perpage-select {
  > .MuiSelect-select {
    border: 1px solid var(--primary-color);
    padding-left: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

.custom-pagination {
  li {
    button {
      border-radius: 50%;
      border: none;
      color: var(--primary-color-02);

      &.Mui-selected {
        color: var(--primary-color);
        background-color: var(--white-color);
        border: 1px solid var(--primary-color);
      }
    }
  }
}

.table-footer-container {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: var(--white-color);
  border-top: 1px solid var(--primary-color-03);
  //Please not remove >
  > .table-pagination-container {
    > .MuiBox-root {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
}

.table-footer-buttons {
  background-color: var(--white-color);
}
