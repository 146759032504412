@font-face {
  font-family: 'Gucci Sans Bold';
  src: url('./light/assets/fonts/GucciSans-Bold.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Serif Poster Medium';
  src: url('./light/assets/fonts/GucciSerifPoster-Medium.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSerifPoster-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Book';
  src: url('./light/assets/fonts/GucciSans-Book.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSans-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Book Italic';
  src: url('./light/assets/fonts/GucciSans-BookItalic.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSans-BookItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Pro';
  src: url('./light/assets/fonts/GucciSansPro-LightItalic.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSansPro-LightItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Pro Medium';
  src: url('./light/assets/fonts/GucciSansPro-Medium.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSansPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Serif Poster Bold';
  src: url('./light/assets/fonts/GucciSerifPoster-Bold.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSerifPoster-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Serif';
  src: url('./light/assets/fonts/GucciSerif-Regular.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSerif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Bold Italic';
  src: url('./light/assets/fonts/GucciSans-BoldItalic.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Gucci Sans Pro Light';
  src: url('./light/assets/fonts/GucciSansPro-Light.woff2') format('woff2'),
  url('./light/assets/fonts/GucciSansPro-Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

