.rounded-tab {
  border: 1px solid var(--tan-color-02);
  border-radius: 26px;
  padding: 0 4px;
  .MuiTabs-indicator{
    height: calc(100% - 8px);
    bottom: 4px;
    border-radius: 26px;
    z-index: -1;
    background-color: var(--tan-color-02);
  }
}
