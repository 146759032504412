.wrapper-with-border {
  border-bottom: 1px solid var(--primary-color-03);
}

.MuiTabs-flexContainer {
  align-items: center;

  .navigation-elem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px !important;
    display: block !important;
  }
}
