.main-navigation-container {
  border-bottom: 1px solid var(--primary-color-03);
  .navigation-elem {
    padding: 25px 10px;
    font-size: var(--font-big);
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 0;
      height: 2px;
      background-color: var(--secondary-color);
      opacity: 0;
      left: 0;
      transition: 0.35s ease-in-out all;
    }
    &:hover {
      &:before {
        opacity: 1;
        width: 100%;
      }
    }
  }
  .MuiTabs-root{
    align-items: center;
    .Mui-selected{
      @apply font-bold;
    }
    .MuiButtonBase-root{
      background-color: transparent;
      transition: none;
    }
    .navigation-icon{
      min-width: 60px;
      min-height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding: 0 10px;
      svg{
        z-index: 1;
      }
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 0;
        background-color: var(--tan-color-02);
        transition: all .5s ease-in-out;
      }
      &.disabled{
        opacity: .2;
        pointer-events: none;
      }
    }
  }
}

