.input {
  text-transform: uppercase;
  min-height: 40px;

  &-small {
    padding: 8px 12px;
  }

  &-big {
    padding: 16px 24px;
  }

  &-primary {
    background-color: var(--white-color);
    border: 1px solid var(--primary-color);
  }

  &-secondary {
    border: 1px solid var(--white-color);
    color: var(--primary-color-03);
    background: transparent;
  }

  &:focus-visible {
    outline: none;
  }

  &-disabled {
    border: 1px solid var(--primary-color-03);
    background-color: var(--primary-color-03);
  }
}
