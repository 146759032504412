:root {
  // colors
  --primary-color:  #000000;
  --primary-color-02: #808080;
  --primary-color-03: #BFBFBF;
  --secondary-color: #4B000A;
  --white-color: #FFFFFF;
  --error-color: #CF002F;
  --success-color: #18a957;
  --tan-color-02: #F6EFE9;
  --tan-color-00: #D0B194;
  --green-color-01: #84C453;


  // text colors
  --primary-text-color: #000000;

  // logo
  --logo: url('../../light/assets/logo.svg');

  // font sizes
  --font-smaller: 10px;
  --font-small: 12px;
  --font-regular: 14px;
  --font-medium-small: 16px;
  --font-medium: 20px;
  --font-big: 24px;
  --font-bigger: 36px;

  // font family
  --font-family: "Gucci Sans Book", system-ui, sans-serif;

  // body
  --body-background-color: #fff;
  --body-color: var(--primary-color);

  //button
  --button-primary-background-color: var(--primary-color);
  --button-primary-color: var(--white-color);
  --button-primary-border-color: var(--primary-color);
  --button-primary-hover-background-color: lighten(var(--button-primary-background-color), 16.08);
  --button-primary-hover-color: var(--secondary-color);
  --button-primary-hover-border-color: lighten(var(--button-primary-background-color), 16.08);
}
